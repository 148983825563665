import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const siteConfigs = [
  {
    // Product School
    apiKey: "AIzaSyBc_MoClveEY78WC6-7--hjH4ZAMFGx_dY",
    authDomain: "product-school-face2face.firebaseapp.com",
    projectId: "product-school-face2face",
    storageBucket: "product-school-face2face.appspot.com",
    messagingSenderId: "478631164350",
    appId: "1:478631164350:web:67d188102e1b58a6a1a1ef",
    pxProductKey: "AP-81DXTR2UDK6D-2",
    siteHosts: ["pschool.face2faceweb.com","product-school-face2face.web.app"]
  },{
    // Trial
    apiKey: "AIzaSyCHKiYuWRklghGjCR9ts_bWxDinYlUnm3Q",
    authDomain: "trial-face2face.firebaseapp.com",
    projectId: "trial-face2face",
    storageBucket: "trial-face2face.appspot.com",
    messagingSenderId: "103266171884",
    appId: "1:103266171884:web:ce84221959e4c9e3ea1ec9",
    pxProductKey: "AP-PXBTDUTAYZ4F-2",
    siteHosts: ["trial.face2faceweb.com","trial-face2face.web.app"]
  },
  {
    apiKey: "AIzaSyB6IZmqewgAw5AeCXPyw4bsXdxycRjvtBg",
    authDomain: "pschool-plg.firebaseapp.com",
    projectId: "pschool-plg",
    storageBucket: "pschool-plg.appspot.com",
    messagingSenderId: "830188349254",
    appId: "1:830188349254:web:c08cd1fde491e8f7de6747",
    pxProductKey: "AP-59XDI3SCNZQI-2",
    siteHosts: ["pschool-plg.face2faceweb.com","pschool-plg.web.app","pschool-plg.firebaseapp.com"]
  },
  {
    // Demo Face2Face
    apiKey: "AIzaSyAvahdw-xFXj9UAC54G9r_2QcRCHcubSkI",
    authDomain: "fir-face2face-fa151.firebaseapp.com",
    projectId: "fir-face2face-fa151",
    storageBucket: "fir-face2face-fa151.appspot.com",
    messagingSenderId: "1005288099795",
    appId: "1:1005288099795:web:000a9a34234556b0f8a926",
    pxProductKey: "AP-UOHKMHUCZEXZ-2",
    siteHosts: ["demo.face2faceweb.com","fir-face2face-fa151.web.app","fir-face2face-fa151.firebaseapp.com"]
  }
]

function getConfigForHost(hostname) {
  let configForHost = null; 
    for (const siteConfig of siteConfigs) {
      if (siteConfig.siteHosts.indexOf(hostname) !== -1) {
        configForHost = siteConfig;
        break;
      }
    }
    return configForHost; 
}

function loadGainsightPX(config) {
    const pxProductKey = config.pxProductKey; 
    if (!pxProductKey) {
      console.log(`Skipping PX load, no product key specified for host '${document.location.host}'`);
      return;
    }
    const pxConfig = {};

    window.aptrinsic = window.aptrinsic || function () {
        (window.aptrinsic.q = window.aptrinsic.q || []).push(arguments)
    }
    window.aptrinsic.p = pxProductKey;
    window.aptrinsic.c = pxConfig;
    
    let r = document.createElement("script");
    r.async = !0;
    r.src = `https://web-sdk.aptrinsic.com/api/aptrinsic.js?a=${pxProductKey}`;
    let c = document.getElementsByTagName("script")[0];
    c.parentNode.insertBefore(r, c);

}

const config = getConfigForHost(document.location.host);
loadGainsightPX(config);
firebase.initializeApp(config);

export const db = firebase.firestore();
export const auth = firebase.auth();

export default firebase;